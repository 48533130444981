import { useDripsyTheme } from 'dripsy';
import { Linking } from 'react-native'
import { H2, H3, View, Text, ScrollView } from '../ui';
import { LabInfo } from '../../models/TrainingData';
import { FilesView } from '../FilesView';
import { Dimensions } from 'react-native';

export default function LabInfoView({ labInfo }: { labInfo: LabInfo }) {
    const { theme } = useDripsyTheme()
    const windowSize: any = Dimensions.get('window').height - 200;
    const LabDetail = ({ title, value, url, center }: { title: string, value: string, url?: string, center?: boolean }) => (
        <>
            <H3>{title}</H3>
            {url && url.length > 0 ? (
                <Text onPress={() => Linking.openURL(url)}>{value}</Text>
            ) : (
                <Text>{value}</Text>
            )
            }
        </>
    )
    const labLink = labInfo.autoLogin ? labInfo.link + '?username=' + labInfo.username + '&password=' + labInfo.password : labInfo.link;
    return (
        <ScrollView sx={{ paddingBottom: theme.space.$5, paddingX: theme.space.$3, maxHeight: windowSize, minWidth: theme.sizes.labMinWidth }}>
            <H2 sx={theme.styles.header}>Lab Information</H2>
            <Text>Your instructor will provide more information when it is time to access your lab</Text>
            {labInfo.info.length > 0 && <LabDetail title="Instructions" value={labInfo.info} />}
            {labInfo.link.length > 0 && <LabDetail title="Link" value={labLink} url={labLink} center={true} />}
            {labInfo.key.length > 0 && <LabDetail title="Key" value={labInfo.key} center={true} />}
            {labInfo.voucher.length > 0 && <LabDetail title="Voucher" value={labInfo.voucher} center={true} />}
            {labInfo.azurePass.length > 0 && <LabDetail title="Azure Pass" value={labInfo.azurePass} center={true} />}
            {labInfo.username.length > 0 && <LabDetail title="Username" value={labInfo.username} center={true} />}
            {labInfo.password.length > 0 && <LabDetail title="Password" value={labInfo.password} center={true} />}
            {labInfo.note.length > 0 && <LabDetail title="Note" value={labInfo.note} center={true} />}
            <FilesView files={labInfo.files} />
        </ScrollView>
    )
}