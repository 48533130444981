import { ActivityIndicator, H2, View } from '../../components/ui';
import { useMediaQuery } from "react-responsive";
import { Pressable } from "../../components/ui/Pressable"
import { ProgramCard } from "../../components/training/ProgramCard";
import { useDripsyTheme } from "dripsy";
import { Training } from "../../models/TrainingData";
import TrainingDetails from "./TrainingDetails";
import { GetHelp } from "../ui/GetHelp";
import CalendarButton from "../ui/CalendarButton";
import Quizzes from "./Quizzes";
import { useQuery } from "@tanstack/react-query";
import { useMemberApi } from "../../api/useMemberApi";

interface TrainingProgramViewProps {
    training: Training
    onPress: (event: Training) => void
}

export default function TrainingProgramView(props: TrainingProgramViewProps) {
    const { training, onPress } = props
    const { getTrainingData, getProgramOpps } = useMemberApi()
    const { theme } = useDripsyTheme()
    const isMobile = useMediaQuery({ query: '(max-width: 1250px)' })
    const isSmallMobile = useMediaQuery({ query: '(max-width: 550px)' })

    const trainingDataQuery = useQuery({ queryKey: ['trainingData'], queryFn: () => getTrainingData(), staleTime: Infinity, enabled: false })
    const { isLoading, isError, data, error, refetch } = useQuery({ queryKey: ['programOpps', training.reggieId], queryFn: () => getProgramOpps(training.reggieId) })

    const programOpps = data

    return (
        <>
            <View sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}>
                <View sx={{ width: isMobile ? '100%' : '50%' }}>
                    <TrainingDetails training={training} onEmailValidation={trainingDataQuery.refetch} />
                </View>
                <View sx={{ width: isMobile ? '100%' : '48%', marginBottom: 20, marginLeft: isSmallMobile ? 0 : isMobile ? '0%' : '2%', minWidth: isSmallMobile ? 'auto' : 580 }}>
                    <View sx={{
                        width: isSmallMobile ? 365 : isMobile ? 425 : 'auto',
                        height: 55,
                        flexDirection: 'row',
                        display: 'flex',
                        marginBottom: 25,
                        marginRight: 10,
                        justifyContent: 'flex-end',
                        zIndex: 100,
                        position: 'relative',
                        right: 0
                    }}>
                        <View sx={{ marginRight: 15 }}>
                            <GetHelp />
                        </View>
                        {programOpps && programOpps.length > 0 && <CalendarButton training={training} programs={programOpps} />}
                    </View>
                    <Quizzes eventId={training.reggieId} />
                    <H2 sx={{ ...theme.styles.classheader, marginTop: 20 }}>Classes In Program</H2>
                    <View sx={{ flexDirection: isSmallMobile ? 'inherit' : 'row', flexWrap: isSmallMobile ? 'inherit' : 'wrap', justifyContent: 'center' }}>
                        {isLoading ? (<ActivityIndicator sx={{ margin: '$4' }} size="large" color="$primary" />) : (
                            <>
                                {programOpps && programOpps.filter((opp) => opp.reggieId != training.reggieId).map((opp, i) => (
                                    <Pressable onPress={() => onPress(opp)} key={opp.id} sx={{ ...theme.box, flexBasis: isSmallMobile ? 'inherit' : 'calc(48% - 10px)' }}>
                                        <ProgramCard training={opp} />
                                    </Pressable>
                                ))}
                            </>
                        )}
                    </View>
                </View>
            </View>
        </>
    )
}