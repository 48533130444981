import { useState, useEffect } from 'react'
import { Text, View, ActivityIndicator } from '../../components/ui'
import FollowOnCourses from './FollowOnCourses'
import BannerAd from '../ui/BannerAd';
import HubModal from '../ui/HubModal';
import ValidateEmail from '../ui/ValidateEmail'
import TrainingCardView from '../TrainingCardView';
import { useMember } from "../../hooks/useMember";
import { useQuery } from '@tanstack/react-query'
import { useMemberApi } from '../../api/useMemberApi'

interface TrainingCardViewProps {
    windowSelection?: (selection: string) => void
}

export default function TrainingView(props: TrainingCardViewProps) {
    const { windowSelection } = props
    const { getTrainingData } = useMemberApi()
    const { isPending, isError, data, error, refetch } = useQuery({ queryKey: ['trainingData'], queryFn: () => getTrainingData(), staleTime: Infinity })
    const { member, setHeader, showHeader } = useMember()
    const [modalVisible, setModalVisible] = useState(false);

    const trainingData = data

    useEffect(() => {
        setHeader(true);
    }, [showHeader])

    const NoTrainingMessage = () => {
        if (trainingData && trainingData.active.length == 0 && trainingData.future.length == 0 && trainingData.past.length == 0) {
            return <Text sx={{ color: '$highlightText', bg: '$highlight', fontWeight: 'bold', padding: '$2' }}>
                This login does not have any classes associated with it. If prompted below, click validate your email for access to previous classes. Otherwise, please create a login with the email address that you registered with. Send us a chat message if you need assistance.
            </Text>
        }
        return null;
    }
    const ValidateEmailMessage = () => {
        if (member && !member.emailValidated && trainingData && (trainingData.active.length > 0 || trainingData.future.length > 0 || trainingData.past.length > 0)) {
            return <Text sx={{ color: '$highlightText', bg: '$highlight', fontWeight: 'bold', padding: '$2' }}>
                Validate your email address to view past classes, course completion certificates, receipts, and other features.
            </Text>
        }
        return null;
    }
    const FooterContent = () => {
        return (
            <><FollowOnCourses courses={trainingData?.followOn} /><BannerAd /></>
        )
    }
    async function onEmailValidation() {
        setModalVisible(false)
        refetch()
    }
    return (
        <>
            {!trainingData || isPending ? (<ActivityIndicator sx={{ marginTop: '$4' }} size="large" color="$primary" />) : (
                <>
                    <NoTrainingMessage />
                    <ValidateEmailMessage />
                    <TrainingCardView
                        trainingData={trainingData}
                        loading={isPending}
                        onRefreshTraining={refetch}
                        setupValidateEmailModal={() => setModalVisible(true)}
                        FooterContent={FooterContent}
                        windowSelection={windowSelection}
                    />
                </>
            )}
            <HubModal
                modalLoading={false}
                modalVisible={modalVisible}
                ModalContent={<ValidateEmail onSuccess={onEmailValidation} />}
                setModalVisible={setModalVisible} />
        </>
    )
}