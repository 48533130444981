import { useDripsyTheme } from 'dripsy';
import { Linking } from 'react-native';
import { View, Text, H2, H3, ScrollView } from '../ui';
import { KitInfo } from "../../models/TrainingData";
import { FilesView } from '../FilesView';
import { Dimensions } from 'react-native';
import { WebViewer } from '../WebViewer';

function KitDetail({ title, text, html, link, linkTitle, center }: { title: string, text?: string, html?: string, link?: string, linkTitle?: string, center?: boolean }) {
    const { theme } = useDripsyTheme()
    const windowSize: any = Dimensions.get('window').height - 200;
    return (
        <>
            {title && title.length > 0 && <H3 sx={{ marginBottom: "$3" }}>{title}</H3>}
            {text && text.length > 0 && <Text sx={{ textAlign: center ? "center" : "left" }}>{text}</Text>}
            {html && html.length > 0 &&
                <View sx={{ paddingX: theme.space.$3, minWidth: theme.sizes.labMinWidth }}>
                    <View sx={{ alignItems: 'flex-start' }}>
                        <WebViewer html={html} scrollEnabled={true}></WebViewer>
                    </View>
                </View>
            }
            {link && link.length > 0 && <Text sx={{ ...theme.styles.link, textAlign: "center" }} onPress={() => Linking.openURL(link)}>{linkTitle ? linkTitle : link}</Text>}
        </>
    )
}

interface kitHTMLText {
    title: string,
    html: string
}

export default function KitInfoView({ kitInfo }: { kitInfo: KitInfo }) {
    const { theme } = useDripsyTheme()
    const windowSize: any = Dimensions.get('window').height - 200;

    const data: kitHTMLText[] = [
        { title: "", html: kitInfo.kitPreMessage },
        { title: "", html: kitInfo.kitText },
        { title: "Directions", html: kitInfo.kitDirections }
    ]

    let html = ""
    data.filter((d) => d.html.length > 0).forEach((d) =>
        html += d.title.length > 0 ? `<h3>${d.title}</h3><p>${d.html}</p>` : `<p>${d.html}</p>`
    )

    return (
        <ScrollView>
            <H2 sx={theme.styles.header}>Course Kit</H2>
            {html.length > 0 && <KitDetail title="" html={html} />}
            {kitInfo.kitCode.length > 0 && <><H3 sx={{ textTransform: 'none' }}>Code: <Text sx={{ fontSize: 16 }}>{kitInfo.kitCode}</Text></H3></>}
            {kitInfo.kitCourseUrl.length > 0 && <KitDetail title="Link" link={`${kitInfo.kitCourseUrl}`} />}
            {kitInfo.kitMaterialUrlExternal.length > 0 && <KitDetail title="Link" link={kitInfo.kitMaterialUrlExternal} linkTitle="EKit Download link" />}
            <FilesView files={kitInfo.kitFiles} />
        </ScrollView>
    )
}