import { useDripsyTheme } from "dripsy";
import { SafeAreaView, Text, View } from "../../components/ui";
import { useEffect, useRef, useState } from "react";
import { FormProvider, SubmitErrorHandler, SubmitHandler, useForm, useWatch } from "react-hook-form";
import { TextInput as NativeTextInput } from "react-native";
import { useMember } from "../../hooks/useMember";
import Member, { Address, AddressTypes } from "../../models/Member";
import FormTextInput from "../ui/forms/FormTextInput";
import { Pressable } from "../ui/Pressable";
import { FontAwesome } from '@expo/vector-icons';
import CountryStateSelector from "../ui/forms/CountryStateSelector";
import ValidateEmail from "../ui/ValidateEmail";
import { Checkbox } from "react-native-paper";
import { Picker } from "@react-native-picker/picker";
import { useConfig } from '../../config/useConfig'

interface FormValues {
    firstName: string
    lastName: string
    company: string
    role: string
    email: string
    password: string
    repeatPassword: string
    country: string
    state: string
    street: string
    city: string
    zip: string
    shipping: Address
    shippingSameAsContact: boolean
}

interface ProfileFormProps {
    showTab?: string
    onSave: (member: Member) => void
}

export default function ProfileForm(props: ProfileFormProps) {
    const { member, roles, saveMember, fetchRoles } = useMember()
    const [updateError, setUpdateError] = useState("")
    const [formSuccess, setFormSuccess] = useState(false)
    const [selectedTab, setSelectedTab] = useState(0)
    const [shippingSameAs, setShippingSameAs] = useState(false)

    const env = useConfig()
    const { ...methods } = useForm<FormValues>({ mode: 'onBlur' })
    const canSubmit = methods.formState.isValid && !methods.formState.isSubmitting
    const codeInputRef = useRef<NativeTextInput>(null)
    const { theme } = useDripsyTheme()

    useEffect(() => {
        async function loadAsync() {
            if (roles.length === 0) {
                try {
                    await fetchRoles()
                } catch (err: any) {
                    console.warn(err.toString())
                }
            }

            methods.watch("role")
        }

        loadAsync();

        if (member) {
            if (member.country && member.country.length > 0) {
                methods.setValue('country', member.country)
                methods.setValue('state', member.state)
                methods.setValue('city', member.city)
                methods.setValue('street', member.street)
                methods.setValue('zip', member.zip)
                methods.setValue('shippingSameAsContact', member.shippingSameAsContact)
            }

            if (member.shipping && member.shipping.country && member.shipping.country.length > 0) {
                methods.setValue('shipping.country', member.shipping.country)
                methods.setValue('shipping.state', member.shipping.state)
                methods.setValue('shipping.city', member.shipping.city)
                methods.setValue('shipping.street', member.shipping.street)
                methods.setValue('shipping.zip', member.shipping.zip)
            }
        }

        if (props.showTab) {
            setSelectedTab(props.showTab == "address" ? 1 : 0)
        }
    }, [])

    const onSubmit: SubmitHandler<FormValues> = async (data) => {
        const { email, firstName, lastName, company, role, password, street, city, country, state, zip, shipping, shippingSameAsContact } = data
        try {
            const member = await saveMember(email, firstName, lastName, company, role, password, country, state, street, city, zip, shipping, shippingSameAsContact, env.brand)
            props.onSave(member)
            setUpdateError("")
            setFormSuccess(true)
        } catch (err: any) {
            setUpdateError(err.toString())
        }
    }

    const onError: SubmitErrorHandler<FormValues> = (errors, e) => {
        return console.error("Form error(s):", errors)
    }

    const onShippingSameAs = (checked: boolean) => {
        setShippingSameAs(checked)
        methods.setValue('shippingSameAsContact', checked)
    }

    const onSelectedRole = (role: string) => {
        methods.setValue("role", role)
    }

    const onSelectedState = async (state: string, prefix?: keyof AddressTypes) => {
        if (prefix) {
            methods.setValue(`${prefix}.state`, state)
        } else {
            methods.setValue("state", state)
        }
    }

    const onSelectedCountry = async (country: string, prefix?: keyof AddressTypes) => {
        if (prefix) {
            methods.setValue(`${prefix}.country`, country)
        } else {
            methods.setValue("country", country)
        }
    }

    const FormMessaging = () => {
        if (updateError.length > 0) {
            return <Text sx={{ color: 'white', bg: '$error', fontWeight: 'bold', padding: '$2', borderRadius: 5, minWidth: 400, textAlign: 'center' }}>{updateError}</Text>
        }

        if (formSuccess) {
            return <Text sx={{ color: '$primary', bg: '$highlight', fontWeight: 'bold', padding: '$2', borderRadius: 5, minWidth: 400, textAlign: 'center' }}>Updated Successfully</Text>
        }

        return null
    }

    type TabTitleProps = {
        title: string
        index: number
        setSelectedTab: (index: number) => void
        opacity: number
    }

    const TabTitle = (props: TabTitleProps) => (
        <Pressable
            onPress={() => props.setSelectedTab(props.index)}
            version="buttons.tab"
            sx={{ opacity: props.opacity }}>
            <Text>{props.title}</Text>
        </Pressable>
    )

    const Tab = ({ title }: { title: string }) => {
        return (
            <>
                <FormTextInput
                    name="street"
                    label="Street"
                    defaultValue={methods.getValues().street}
                    rules={{
                        required: "Street Required"
                    }}
                />
                <FormTextInput
                    name="city"
                    label="City"
                    defaultValue={methods.getValues().city}
                    rules={{
                        required: "City Required"
                    }}
                />

                <CountryStateSelector onSelectState={onSelectedState} onSelectCountry={onSelectedCountry} selectedCountry={methods.getValues().country} selectedState={methods.getValues().state} />

                <FormTextInput
                    name="zip"
                    label="Zip/Postal Code"
                    defaultValue={methods.getValues().zip}
                    rules={{
                        required: "Zip/Postal Code Required"
                    }}
                />

                <View sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                    <Checkbox.Android
                        status={methods.getValues().shippingSameAsContact ? 'checked' : 'unchecked'}
                        onPress={() => onShippingSameAs(!shippingSameAs)}
                        color={theme.colors.$primary}
                    />
                    <Text>Shipping same as contact?</Text>
                </View>

                {!shippingSameAs && !methods.getValues().shippingSameAsContact && <>
                    <Text sx={{ fontWeight: 'bold', textAlign: 'center', color: '$primary', textTransform: 'uppercase', fontSize: '$4' }}>Shipping Info</Text>

                    <FormTextInput
                        name="shipping.street"
                        label="Street"
                        defaultValue={methods.getValues().shipping ? methods.getValues().shipping.street : ""}
                        rules={{
                            required: "Street Required"
                        }}
                    />
                    <FormTextInput
                        name="shipping.city"
                        label="City"
                        defaultValue={methods.getValues().shipping ? methods.getValues().shipping.city : ""}
                        rules={{
                            required: "City Required"
                        }}
                    />

                    <CountryStateSelector prefix="shipping" onSelectState={onSelectedState} onSelectCountry={onSelectedCountry} selectedCountry={methods.getValues().shipping ? methods.getValues().shipping.country : ""} selectedState={methods.getValues().shipping ? methods.getValues().shipping.state : ""} />

                    <FormTextInput
                        name="shipping.zip"
                        label="Zip/Postal Code"
                        defaultValue={methods.getValues().shipping ? methods.getValues().shipping.zip : ""}
                        rules={{
                            required: "Zip/Postal Code Required"
                        }}
                    />
                </>}
            </>
        )
    }

    const ProfileTab = () => {
        return (
            <>
                <FormTextInput
                    name="email"
                    label="Email"
                    defaultValue={member && member.email}
                    rules={{
                        required: "Email Required"
                    }}
                />
                {member?.emailValidated && <FontAwesome
                    name="check-circle"
                    size={24}
                    color={theme.colors.$highlight}
                    style={{ position: 'absolute', top: 38, right: 12 }}
                />}
                <FormTextInput
                    name="firstName"
                    label="First Name"
                    defaultValue={member && member.firstName}
                    rules={{
                        required: "First Name Required"
                    }}
                />
                <FormTextInput
                    name="lastName"
                    label="Last Name"
                    defaultValue={member && member.lastName}
                    rules={{
                        required: "Last Name Required"
                    }}
                />
                <FormTextInput
                    name="company"
                    label="Company"
                    defaultValue={member && member.company}
                    rules={{
                        required: "Company Required"
                    }}
                />

                <Text sx={{ paddingLeft: '$2', color: '$primary', fontWeight: 'bold' }}>I am a</Text>
                <Picker
                    selectedValue={methods.getValues().role ?? (member ? member.role : undefined)}
                    onValueChange={(itemValue, itemIndex) => {
                        onSelectedRole(itemValue)
                        useWatch
                    }}
                    style={{ backgroundColor: theme.colors.$gray, padding: theme.space.$2, margin: theme.space.$2, width: '96%', lineHeight: 24 }}
                >
                    <Picker.Item key='' label="-- Select --" value={null} enabled={true} />
                    {roles && roles.map((role, index) => (
                        <Picker.Item key={index} label={role} value={role} />
                    ))}
                </Picker>

                <FormTextInput
                    name="password"
                    label="Password"
                    defaultValue=""
                    placeholder="********"
                    autoCompleteType="password"
                    textContentType="password"
                    secureTextEntry={true}
                    rules={{
                        minLength: { value: 8, message: "Min Length 8" }
                    }}
                />
                <FormTextInput
                    name="repeatPassword"
                    label="Repeat Password"
                    defaultValue=""
                    placeholder="********"
                    autoCompleteType="password"
                    textContentType="password"
                    secureTextEntry={true}
                    rules={{
                        minLength: { value: 8, message: "Min Length 8" },
                        validate: {
                            matchesPassword: (value) => {
                                const { password } = methods.getValues()
                                return password === value || "Password don't match"
                            }
                        }
                    }}
                />
            </>
        )
    }

    const Tabs = ({ children }: { children: React.ReactElement[] }) => {

        return (
            <>
                <View sx={{ flexDirection: 'row', width: '100%', maxWidth: 400 }}>
                    {["Profile", "Address"].map((item, index) => (
                        <TabTitle
                            key={index}
                            index={index}
                            title={item}
                            setSelectedTab={setSelectedTab}
                            opacity={selectedTab === index ? 1 : 0.5}
                        />
                    ))}
                </View>
                <View sx={{ position: 'relative', width: '100%', maxWidth: 400 }}>
                    {children[selectedTab]}
                </View>
            </>
        )
    }

    return (

        <SafeAreaView sx={{ justifyContent: 'center', alignItems: 'center' }}>

            <FormProvider {...methods}>
                <Tabs>
                    <ProfileTab />
                    <Tab title="Address" />
                </Tabs>

                <Pressable
                    onPress={methods.handleSubmit(onSubmit, onError)}
                    version="buttons.primary">
                    <Text sx={{ color: 'white' }}>Update</Text>
                </Pressable>
            </FormProvider>

            <FormMessaging />

            <ValidateEmail />

        </SafeAreaView>
    )
}